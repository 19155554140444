import { NavLink } from './atoms'
import { GetStaticProps } from 'next'
import { GitHub24, MailAt24, Twitter24 } from './icons'
import { RichPresenceList } from '@/components/richPresenceList'

import { getCurrentlyPlaying, getRecentlyPlayed } from '@/lib/spotify'

import {
  SpotifyCurrentTrack,
  SpotifyRecentTracks,
} from '@/types/rich-presence'

const routes = [
  {
    path: '/',
    label: 'HOME',
  },
  {
    path: '/blog',
    label: 'BLOG',
  },
  {
    path: '/projects',
    label: 'PROJECTS',
  },
  {
    path: '/about',
    label: 'ABOUT',
  },
]

interface AboutPageProps {
  spotify?: SpotifyCurrentTrack | SpotifyRecentTracks
}

export function Footer() {
  return (
    <footer className="relative w-full h-56 overflow-hidden bg-back-secondary text-secondary">
      <div className="container h-full px-5 pt-24 pb-12 m-auto md:max-w-screen-md lg:max-w-screen-lg md:px-20">
        <div className="container flex-col justify-between md:space-y-8">
          <nav className="flex-row items-center justify-center hidden space-x-6 text-sm lg:flex md:justify-end">
            {routes.map(route => (
              <NavLink key={route.path} to={route.path} title={route.label}>
                {route.label}
              </NavLink>
            ))}
          </nav>
          <div className="flex flex-col-reverse space-y-8 space-y-reverse md:flex-row md:justify-between md:space-y-0">
            <span
              className="self-center text-xs tracking-wider md:self-end"
              aria-label="Copyright"
            >
              
              © 2023 Nick Macioce. All Rights Reserved.
            </span>
            <div className="flex flex-row items-center justify-center space-x-6 md:justify-end">
              <span>
                <a
                  href="mailto:ndmacioce@gmail.com.com"
                  aria-label="Email ndmacioce@gmail.com"
                  title="Email"
                  className="focus-visible:outline-accent focus:text-accent"
                >
                  <MailAt24 className="transition-transform ease-in-out hover:-translate-y-1 hover:text-accent" />
                </a>
              </span>
              <span>
                <a
                  href="https://github.com/ndmacioce"
                  aria-label="Visit GitHub profile"
                  title="Visit GitHub profile"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="focus-visible:outline-accent focus:text-accent"
                >
                  <GitHub24 className="transition-transform ease-in-out hover:-translate-y-1 hover:text-accent" />
                </a>
              </span>
              {/* <span>
                <a
                  href="https://twitter.com/ndmacioce"
                  aria-label="Visit Twitter profile"
                  title="Visit Twitter profile"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="focus-visible:outline-accent focus:text-accent"
                >
                  <Twitter24 className="transition-transform ease-in-out hover:-translate-y-1 hover:text-accent" />
                </a>
              </span> */}
            </div>
          </div>
        </div>
      </div>
      {/* <RichPresenceList presenceList={[spotify]} /> */}
    </footer>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  let spotify:
    | SpotifyCurrentTrack
    | SpotifyRecentTracks = await getCurrentlyPlaying()
  if (spotify === null) {
    spotify = await getRecentlyPlayed()
  }

  // const letterboxd = await getRecentMovies()
  // const steam = await getRecentGames()
  // const notion = await getDoingNow()
  return {
    props: {
      spotify,
    },
    revalidate: 60,
  }
}
